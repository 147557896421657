import { memo } from 'react';
import { InView } from 'react-intersection-observer';
import type { TProductCardVariant } from '@insuma/mpp-ui/components/product-card';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { EProductOrigin } from 'core/model/enums/gtm.enum';
import { IProduct } from 'core/model/interfaces/product.interface';
import { isObjectSimpleEqual } from 'shared/utils/object.utils';
import { SeeMoreButton } from '../../see-more-button';
import { CarouselCardProductsBody } from './components/carousel-card-products-body';

import './carousel-card-products-generic.scss';

interface ICarouselCardProductsGenericProps {
  listName?: string;
  origin: EProductOrigin;
  title: string;
  gtmRegisterListProducts: (products: Array<IProduct>) => void;
  handleSeeAll: () => void;
  variant?: TProductCardVariant;
  products: Array<IProduct>;
  totalItems: number;
  showSeeMore: boolean;
  shouldHideHeader?: boolean;
  suggestedProductType?: string;
}

export const CarouselCardProductsGeneric = memo(
  ({
    products,
    listName = 'null',
    origin,
    title,
    handleSeeAll,
    gtmRegisterListProducts,
    suggestedProductType,
    variant = 'default',
    totalItems,
    showSeeMore,
    shouldHideHeader = false,
  }: ICarouselCardProductsGenericProps) => {
    const isSmall = variant === 'simplified';
    const css = useCSS('carousel-card-products-generic');

    return (
      <div>
        {products?.length !== 0 && (
          <div role="region" className={css()}>
            {!shouldHideHeader && (
              <div className={css('header', { small: isSmall })}>
                <div className={css('header-content')}>
                  <h3 className={css('title', { small: isSmall })} aria-label={title}>
                    {title}
                  </h3>
                  <div className={css('amount')}>{totalItems} Productos</div>
                </div>
                {showSeeMore && <SeeMoreButton onClick={handleSeeAll} />}
              </div>
            )}
            <InView
              as="div"
              aria-live="polite"
              aria-label="lista de productos"
              triggerOnce
              onChange={inView => inView && gtmRegisterListProducts(products)}
              className={css('body')}
            >
              <CarouselCardProductsBody
                handleSeeAll={handleSeeAll}
                products={products}
                title={title}
                origin={origin}
                listName={listName}
                variant={variant}
                seeMore={showSeeMore}
                suggestedProductType={suggestedProductType}
              />
            </InView>
          </div>
        )}
      </div>
    );
  },
  (prevProps, nextProps) => isObjectSimpleEqual(prevProps, nextProps),
);
