import { Heading, Text } from '@alicorpdigital/dali-components-react';
import { Button } from '@insuma/mpp-ui/components/button';
import { MainContainer } from 'shared/components/main-components';
import { MovingEye } from 'shared/components/moving-eye';

import './rollback-internal-error.scss';

export const RollbackInternalError = () => (
  <MainContainer classNames="rollback-internal-error">
    <Heading className="rollback-internal-error__title" size="xxl">
      <span>
        ¡Ups, alg<span className="rollback-internal-error__title__character">o</span>
        <MovingEye />
      </span>
      <span>
        {' '}
        <MovingEye />
        <span className="rollback-internal-error__title__character">o</span>currió!
      </span>
    </Heading>
    <Text> Lo arreglaremos en un instante. </Text>
    <Button
      className="rollback-internal-error__button"
      size="sm"
      onClick={() => window.location.assign(window.location.origin)}
    >
      Regresar al inicio
    </Button>
  </MainContainer>
);
