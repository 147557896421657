import { Suspense, useEffect } from 'react';
import { SkeletonPlaceHolder } from '@insuma/mpp-ui/components/skeleton';
import { useCSS } from '@insuma/mpp-ui/hooks';
import usePaginatedCatalogs from 'core/hooks/products/use-paginated-catalogs';
import { EProductOrigin } from 'core/model/enums/gtm.enum';
import { EInsiderPageType } from 'core/model/enums/insider.enum';
import { ESuggestedProductViewId } from 'core/model/enums/suggested-products.enum';
import { IProduct } from 'core/model/interfaces/product.interface';
import { hasBannersSelector } from 'core/store/customization/selectors';
import { navigationSelector } from 'core/store/navigation/selectors';
import { useAppSelector } from 'core/store/store.utils';
import { CarouselCardPromotions } from 'screens/home/components/carousel-card-promotions';
import { useOnlyProductPromotions } from 'screens/promotion-products/hooks/use-only-product-promotions';
import { CarouselCardSuggestion } from 'screens/suggested-products/components';
import { useSuggestions } from 'screens/suggested-products/hooks/use-suggestions';
import { CarouselCardProducts } from 'shared/components/products';
import CarouselSkeleton from 'shared/components/products/carousel-skeleton';
import { SliderLoading } from 'shared/components/slider/components/slider-loader';
import { setInsiderProductList, settingInsiderPageType } from 'shared/utils/insider.utils';
import { ShortcutsBannersList } from './components/shortcuts-banner';
import { TopbarBanner } from './components/topbar-banner';
import { LazySlidesBanner } from './home.lazy';

import './home.scss';

const Home = () => {
  const css = useCSS('home');
  const { categories } = useAppSelector(navigationSelector);
  const hasBanners = useAppSelector(hasBannersSelector);
  const { suggestions, areSuggestionsLoading } = useSuggestions(ESuggestedProductViewId.homepage, false);
  const { paginatedProducts: promotionProducts, loading: arePromotionsLoading } = useOnlyProductPromotions();
  const { catalogs, isLoading, lastItemRef, isIdle } = usePaginatedCatalogs(
    categories.list.map(item => ({ id: item.itemId.toString(), name: item.name })),
  );

  useEffect(() => {
    settingInsiderPageType(EInsiderPageType.HOME);
  }, []);

  useEffect(() => {
    const totalProducts: Array<IProduct> = [
      ...catalogs.flatMap(catalog => catalog.products),
      ...suggestions.flatMap(suggestion => suggestion.products),
    ];

    if (totalProducts.length > 0) setInsiderProductList(totalProducts);
  }, [suggestions, catalogs]);

  return (
    <div className={css()}>
      <TopbarBanner />
      <div className={css('container')}>
        <Suspense fallback={<SliderLoading />}>{hasBanners && <LazySlidesBanner />}</Suspense>
        <ShortcutsBannersList />
        <div className={css('products')}>
          <SkeletonPlaceHolder
            loading={arePromotionsLoading && isIdle}
            skeleton={<CarouselSkeleton ariaLabel="cargando promociones" />}
          >
            <CarouselCardPromotions
              products={promotionProducts?.products}
              title="Promociones para ti"
              quantity={promotionProducts.pagination.totalItems}
              hasMoreProducts={promotionProducts.pagination.totalPages > 1}
            />
          </SkeletonPlaceHolder>

          <SkeletonPlaceHolder
            loading={areSuggestionsLoading || (isLoading && isIdle)}
            skeleton={<CarouselSkeleton ariaLabel="cargando productos sugeridos" />}
          >
            {suggestions?.map((catalog, index) => (
              <CarouselCardSuggestion
                key={`carousel-card-products-${index}`}
                catalog={catalog}
                origin={EProductOrigin.SUGGESTED_PRODUCTS_HOME}
                suggestedProductType={catalog.type}
              />
            ))}
          </SkeletonPlaceHolder>

          {catalogs.map((catalog, index) => (
            <CarouselCardProducts
              // TODO: Cambiar guid por id y que sea number
              key={`carousel-card-products-${index}-${catalog.category?.guid ?? ''}`}
              // TODO: Mejorar tipado opcional del category name
              listName={catalog?.category?.name}
              catalog={catalog}
              origin={EProductOrigin.HOME}
            />
          ))}
          <div ref={lastItemRef} id="last-item">
            {(isLoading || isIdle) && <CarouselSkeleton />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
