import { GenericAbortSignal } from 'axios';
import { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'core/services/https';
import {
  IGetCatalogProductsDTO,
  IGetCatalogProductsParams,
  IGetListProductsDTO,
  IGetListProductsParams,
  IGetProductDetailDTO,
  IGetProductDetailParams,
} from './catalogs.dto';

export const baseUrl = '/gtm-product-management';

export const catalogsData = {
  getListProducts: async (
    params: IGetListProductsParams,
    customerId: number,
    signal?: GenericAbortSignal,
  ): Promise<IGetListProductsDTO> => {
    const { data } = await https.get<IServiceSuccess<IGetListProductsDTO>>(`${baseUrl}/v1/products/catalog`, {
      params,
      headers: { 'customer-id': customerId },
      signal,
    });

    return data;
  },

  getProductDetail: async ({ sku }: IGetProductDetailParams, customerId: number): Promise<IGetProductDetailDTO> => {
    const { data } = await https.get<IServiceSuccess<IGetProductDetailDTO>>(`${baseUrl}/v1/products/catalog/${sku}`, {
      headers: { 'customer-id': customerId },
    });
    return data;
  },

  getCatalogProducts: async (
    params: IGetCatalogProductsParams,
    customerId: number,
  ): Promise<IGetCatalogProductsDTO> => {
    const url = `${baseUrl}/v1/navigation/products`;
    const { data } = await https.get<IServiceSuccess<IGetCatalogProductsDTO>>(url, {
      params,
      headers: { 'customer-id': customerId },
    });

    return data;
  },
};
