import { Icon } from '@insuma/mpp-ui/components/icon';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { TSeeMoreCardVariant } from './see-more-card.types';

import './see-more-card.scss';

interface ISeeMoreCardProps {
  handleSeeAll: () => void;
  title: string;
  variant: TSeeMoreCardVariant;
}

export const SeeMoreCard = ({ handleSeeAll, title, variant = 'default' }: ISeeMoreCardProps) => {
  const isSmall = variant === 'simplified';
  const css = useCSS('mpp-see-more-card');

  return (
    <article className={css({ small: isSmall })}>
      <div className={css('content')}>
        <h4 className={css('title')}>{title}</h4>
        <button onClick={handleSeeAll} className={css('button')} type="button">
          Ver más <Icon name="caret-right" size="sm" />
        </button>
      </div>
    </article>
  );
};
