import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import type { TCounterVariant } from '@insuma/mpp-ui/components/product-counter';
import { Path } from 'core/constants/path.constants';
import { EProductOrigin, EZoneEntryMenu } from 'core/model/enums/gtm.enum';
import { IProduct } from 'core/model/interfaces/product.interface';
import { ISuggestionSection } from 'core/model/interfaces/suggested-products.interface';
import { CarouselCardProductsGeneric } from 'shared/components/products';
import gtmEvents from 'shared/utils/gtm-events';

export interface ICarouselCardSuggestionProps {
  catalog: ISuggestionSection;
  origin: EProductOrigin;
  extraHandleSeeAll?: () => void;
  shouldTag?: boolean;
  variant?: TCounterVariant;
  shouldSeeMore?: boolean;
  shouldHideTitle?: boolean;
  suggestedProductType: string;
}

export const CarouselCardSuggestion = ({
  catalog,
  origin,
  extraHandleSeeAll,
  suggestedProductType,
  shouldTag = true,
  variant = 'default',
  shouldSeeMore = true,
  shouldHideTitle = false,
}: ICarouselCardSuggestionProps) => {
  const { products, hasMoreProducts, quantity, title } = catalog;

  const totalItems = quantity || products?.length;
  const seeMore = shouldSeeMore && hasMoreProducts;

  const navigate = useNavigate();

  const handleSeeAll = useCallback(() => {
    if (extraHandleSeeAll) extraHandleSeeAll();

    gtmEvents.triggerEntryMenuEvent({
      zone: EZoneEntryMenu.SUGGESTED_PRODUCTS,
      category: title,
      subcategory: 'Todos',
    });

    return navigate(Path.SUGGESTED_PRODUCTS_PAGE, { state: { fromCarousel: true } });
  }, [navigate, title, extraHandleSeeAll]);

  const gtmRegisterListProducts = useCallback(
    (visibleProducts: Array<IProduct>) => {
      if (shouldTag) {
        gtmEvents.triggerViewProductListEvent({
          products: visibleProducts,
          origin,
        });
      }
    },
    [shouldTag, origin],
  );

  return (
    <CarouselCardProductsGeneric
      products={products}
      origin={origin}
      title={title}
      handleSeeAll={handleSeeAll}
      gtmRegisterListProducts={gtmRegisterListProducts}
      variant={variant}
      totalItems={totalItems}
      showSeeMore={seeMore}
      shouldHideHeader={shouldHideTitle}
      suggestedProductType={suggestedProductType}
    />
  );
};
