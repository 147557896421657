import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Path } from 'core/constants/path.constants';
import { EProductOrigin } from 'core/model/enums/gtm.enum';
import { IProduct } from 'core/model/interfaces/product.interface';
import { CarouselCardProductsGeneric } from 'shared/components/products';
import gtmEvents from 'shared/utils/gtm-events';

export interface ICarouselCardPromotionsProps {
  title: string;
  products: Array<IProduct>;
  quantity: number;
  hasMoreProducts: boolean;
}

export const CarouselCardPromotions = ({
  title,
  products,
  quantity,
  hasMoreProducts,
}: ICarouselCardPromotionsProps) => {
  const totalItems = quantity || products?.length;
  const seeMore = hasMoreProducts;
  const origin = EProductOrigin.PROMOTIONS_PRODUCTS_HOME;

  const navigate = useNavigate();

  const handleSeeAll = useCallback(() => navigate(Path.PROMOTIONS), [navigate]);

  const gtmRegisterListProducts = useCallback(
    (visibleProducts: Array<IProduct>) => {
      gtmEvents.triggerViewProductListEvent({
        products: visibleProducts,
        origin,
      });
    },
    [origin],
  );

  return (
    <CarouselCardProductsGeneric
      products={products}
      origin={origin}
      title={title}
      handleSeeAll={handleSeeAll}
      gtmRegisterListProducts={gtmRegisterListProducts}
      totalItems={totalItems}
      showSeeMore={seeMore}
    />
  );
};
