import {
  EProductCardTagType,
  IProductCardInfoLine,
  type IProductCardTag,
  type ISimplifiedProduct,
} from '@insuma/mpp-ui/components/product-card';
import { ECustomerSource } from 'core/model/enums/customer.enum';
import { EPromotionDetailType, EPromotionType } from 'core/model/enums/promotion.enum';
import { IPromoCart } from 'core/model/interfaces/discount.interface';
import {
  ICartProduct,
  IProduct,
  IPromoIndicators,
  IUnitMeasure,
  IUnitMeasureDetail,
} from 'core/model/interfaces/product.interface';
import { canPurchaseProduct } from './credit.utils';
import { cloneDeepSimple } from './object.utils';

interface ICardProductData {
  product: ISimplifiedProduct;
  unitMeasureCode: string;
  tags: Array<IProductCardTag>;
  infoLine: IProductCardInfoLine | undefined;
}

interface ISearchCardProductData {
  product: ISimplifiedProduct;
  tags: Array<IProductCardTag>;
  infoLine: IProductCardInfoLine | undefined;
}

interface IProductToCardProductDataParams {
  product: IProduct;
  cartProduct?: ICartProduct;
  customerSource: ECustomerSource;
  canPurchaseDex: boolean;
  canPurchaseApudex: boolean;
}

export const updateSelectedUnitMeasure = <T extends { code: string }>(
  unitMeasure: T,
  selectedUnitMeasureCode: string,
  newUnitMeasure?: T,
) =>
  unitMeasure.code === selectedUnitMeasureCode
    ? { ...(newUnitMeasure ?? unitMeasure), selected: true }
    : { ...unitMeasure, selected: false };

export const getStockBySelectedUnitMeasure = <T extends { conversionFactor: number }>(
  stock?: number,
  selectedUnitMeasure?: T,
): number => {
  if (stock !== undefined && selectedUnitMeasure !== undefined) {
    return Math.floor(stock / selectedUnitMeasure.conversionFactor);
  }
  return 0;
};

export const getProductCommercialName = (commercialName: string | undefined | null, fallbackName: string) => {
  if (commercialName !== '' && commercialName !== null && commercialName !== undefined) return commercialName;
  return fallbackName;
};

export const getPresentationIcon = (unitMeasure: IUnitMeasure) => (!unitMeasure.default ? 'package' : 'box-milk');

export const shouldHighlightFinalPrice = (unitMeasure: IUnitMeasure | IUnitMeasureDetail) => {
  if (Array.isArray(unitMeasure.promotions)) {
    const hasSimpleDiscount = unitMeasure.promotions?.some(promo => promo.type === EPromotionType.SIMPLE_DISCOUNT);
    const hasTieredDiscount = unitMeasure.promotions?.some(promo => promo.type === EPromotionType.TIERED_DISCOUNT);
    return hasSimpleDiscount || hasTieredDiscount;
  }
  const hasSimpleDiscount = unitMeasure.promotions.simple.some(promo => promo.type === EPromotionDetailType.DISCOUNT);
  const hasTieredDiscount = unitMeasure.promotions.tiered.some(promo => promo.type === EPromotionDetailType.DISCOUNT);
  return hasSimpleDiscount || hasTieredDiscount;
};

export const hasReducedPrice = (promos: Array<IPromoCart>) => promos.filter(promo => promo.value !== null).length > 0;

export const getTags = (promoIndicators: IPromoIndicators): Array<IProductCardTag> => {
  const tags: Array<IProductCardTag> = [];

  if (promoIndicators.hasDiscount || promoIndicators.hasBonus)
    tags.push({ labelText: 'Promoción', type: EProductCardTagType.POSITIVE });
  if (promoIndicators.hasCombo) tags.push({ labelText: 'Arma tu combo', type: EProductCardTagType.INFO });

  return tags;
};

export const getInfoLine = (promoIndicators: IPromoIndicators): IProductCardInfoLine | undefined => {
  if (promoIndicators.maxDiscount)
    return {
      text: `Hasta ${(promoIndicators.maxDiscount * 1000) / 10}% de dscto`,
      icon: { name: 'promotion', weight: 'fill' },
    };

  if (promoIndicators.hasBonus) return { text: '¡Llévate un regalo!', icon: { name: 'gift', weight: 'fill' } };
};

export const getProductTotalDiscountValue = (discounts: Array<IPromoCart> = []) =>
  discounts.reduce((current, promo) => {
    const value = promo.value || 0;
    return current + value;
  }, 0);

export const mapProductToCardProductData = ({
  product,
  cartProduct,
  customerSource,
  canPurchaseDex,
  canPurchaseApudex,
}: IProductToCardProductDataParams): ICardProductData => {
  const finalProduct = cloneDeepSimple(product);

  if (cartProduct) {
    finalProduct.unitMeasures = finalProduct.unitMeasures.map(um =>
      updateSelectedUnitMeasure(um, cartProduct.unitMeasure.code, cartProduct.unitMeasure),
    );
  }
  const promoIndicators = getAllPromoIndicators(finalProduct.unitMeasures);

  const selectedUnitMeasure = cartProduct ? cartProduct.unitMeasure : getSelectedUnitMeasure(finalProduct.unitMeasures);
  const {
    originalPrice,
    presentation,
    maximumSaleQuantity,
    minimumSaleQuantity,
    code: unitMeasureCode,
    price,
  } = selectedUnitMeasure;
  const { currency, image, stock, commercialName } = finalProduct;

  const isActiveProduct = canPurchaseProduct({
    productSource: product.sourceId,
    customerSource,
    canPurchaseDex,
    canPurchaseApudex,
  });

  return {
    product: {
      active: isActiveProduct,
      currencySymbol: currency?.symbol,
      finalPrice: price,
      highlightFinalPrice: shouldHighlightFinalPrice(selectedUnitMeasure),
      image,
      regularPrice: originalPrice,
      stock: getStockBySelectedUnitMeasure(stock, selectedUnitMeasure),
      subtitle: presentation,
      title: commercialName,
      maximumSaleQuantity,
      minimumSaleQuantity,
    },
    unitMeasureCode,
    tags: getTags(promoIndicators),
    infoLine: getInfoLine(promoIndicators),
  };
};

export const productToSearchProductPreviewProps = (product: IProduct): ISearchCardProductData => {
  const promoIndicators = getAllPromoIndicators(product.unitMeasures);
  const selectedUnitMeasure = getSelectedUnitMeasure(product.unitMeasures);
  const { originalPrice, presentation, price } = selectedUnitMeasure;
  return {
    product: {
      title: getProductCommercialName(product.commercialName, product.name),
      regularPrice: originalPrice,
      highlightFinalPrice: shouldHighlightFinalPrice(selectedUnitMeasure),
      image: product.image,
      subtitle: presentation,
      finalPrice: price,
      currencySymbol: product.currency.symbol,
      active: true,
      stock: product.stock,
    },
    tags: getTags(promoIndicators),
    infoLine: getInfoLine(promoIndicators),
  };
};

export const hasAllKindsOfPromos = <T extends { promoIndicators: IPromoIndicators }>(unitMeasure: T) =>
  unitMeasure.promoIndicators.hasBonus ||
  unitMeasure.promoIndicators.hasDiscount ||
  unitMeasure.promoIndicators.hasCombo;

export const getUnitMeasureByDefault = <T extends { default: boolean }>(unitMeasures: Array<T>, isDefault = true) =>
  unitMeasures.find(um => um.default === isDefault) || unitMeasures[0];

export const getDefaultUnitMeasure = <T extends { default: boolean }>(unitMeasures: Array<T>) =>
  unitMeasures.find(um => um.default === true) || unitMeasures[0];

export const getSelectedUnitMeasure = <T extends { default: boolean; selected?: boolean }>(unitMeasures: Array<T>) =>
  unitMeasures.find(unitMeasure => unitMeasure.selected) || getDefaultUnitMeasure(unitMeasures);

export const cartProductToProduct = (product: ICartProduct): IProduct => ({
  ...product,
  unitMeasures: [product.unitMeasure],
});
export const getUnitMeasureByCode = <T extends { code: string; default: boolean }>(
  unitMeasures: Array<T>,
  code: string,
) => unitMeasures.find(unitMeasure => unitMeasure.code === code) || getDefaultUnitMeasure(unitMeasures);

export const getAllPromoIndicators = (unitMeasures: Array<IUnitMeasure>) =>
  unitMeasures
    .flatMap(um => um.promoIndicators)
    .reduce(
      (acc, promo) => ({
        hasDiscount: acc.hasDiscount || promo.hasDiscount,
        hasBonus: acc.hasBonus || promo.hasBonus,
        hasCombo: acc.hasCombo || promo.hasCombo,
        maxDiscount: promo.maxDiscount ? Math.max(promo.maxDiscount, acc.maxDiscount || 0) : acc.maxDiscount,
      }),
      {
        hasDiscount: false,
        hasCombo: false,
        hasBonus: false,
        maxDiscount: 0,
      },
    );
