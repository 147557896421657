import { ProductCard, TProductCardVariant } from '@insuma/mpp-ui/components/product-card';
import { SkeletonPlaceHolder } from '@insuma/mpp-ui/components/skeleton';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { PRODUCT_COUNTER_CLICKING_DELAY } from 'core/constants/catalogue.constants';
import { ImageRoutes } from 'core/constants/image-routes';
import { useProductActions } from 'core/hooks/products/use-product-actions';
import { EProductOrigin } from 'core/model/enums/gtm.enum';
import { IProduct } from 'core/model/interfaces/product.interface';
import { authGetCustomerSource } from 'core/store/auth/selectors';
import { allProductsByUserSourceSelector } from 'core/store/cart/selectors';
import { canBuyByDistributorSelector } from 'core/store/credits/selectors';
import { useAppSelector } from 'core/store/store.utils';
import { SeeMoreCard } from 'shared/components/products/carousel-card-products-generic/components/see-more-card';
import CarouselSkeleton from 'shared/components/products/carousel-skeleton';
import { SlickSlider } from 'shared/components/slick-slider';
import { mapProductToCardProductData } from 'shared/utils/products.utils';

import './carousel-card-products-body.scss';

interface ICarouselCardProductProps {
  handleSeeAll: () => void;
  listName: string;
  origin: EProductOrigin;
  products: Array<IProduct>;
  seeMore: boolean;
  title: string;
  suggestedProductType?: string;
  variant?: TProductCardVariant;
}

export const CarouselCardProductsBody = ({
  handleSeeAll,
  listName,
  origin,
  products,
  seeMore,
  title,
  suggestedProductType,
  variant = 'default',
}: ICarouselCardProductProps) => {
  const css = useCSS('carousel-card-products-body');
  const cartProducts = useAppSelector(allProductsByUserSourceSelector);
  const customerSource = useAppSelector(authGetCustomerSource);
  const { canPurchaseApudex, canPurchaseDex } = useAppSelector(canBuyByDistributorSelector);
  const { onAbort, onChangeCounterQuantity, onDetailProduct } = useProductActions({
    listName,
    origin,
  });

  const renderProductCards = () => {
    const productCards = products.map((item, index) => {
      const cartProduct = cartProducts?.find(p => p.sku === item.sku);
      const quantity = cartProduct?.quantity ?? 0;
      const {
        product: cardProductData,
        unitMeasureCode,
        tags,
        infoLine,
      } = mapProductToCardProductData({
        product: item,
        cartProduct,
        customerSource,
        canPurchaseDex,
        canPurchaseApudex,
      });

      return (
        <ProductCard
          className={css()}
          clickingDelay={PRODUCT_COUNTER_CLICKING_DELAY}
          defaultImageSrc={ImageRoutes.CATALOGUE_DEFAULT_PRODUCT}
          isLoading={false}
          key={`product-card-${item.sku}-${title}`}
          onAbort={() => onAbort(item.sku, unitMeasureCode)}
          onCardClick={() => onDetailProduct(item)}
          onChangeQuantity={newQuantity =>
            onChangeCounterQuantity({
              item,
              currentQuantity: quantity,
              newQuantity,
              unitMeasureCode,
              index,
              metadata: cartProduct?.metadata,
              suggestedProductType,
            })
          }
          product={cardProductData}
          quantity={quantity}
          tags={tags}
          variant={variant}
          active={cardProductData.active}
          infoLine={infoLine}
        />
      );
    });

    if (seeMore) {
      productCards.push(
        <SeeMoreCard variant={variant} key={Math.random()} title={title} handleSeeAll={handleSeeAll} />,
      );
    }

    return productCards;
  };

  return (
    <SkeletonPlaceHolder
      loading={!products?.length}
      skeleton={<CarouselSkeleton ariaLabel="cargando productos" variant={variant} />}
    >
      <SlickSlider items={renderProductCards()} />
    </SkeletonPlaceHolder>
  );
};
